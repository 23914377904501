<template>
  <b-form @submit.stop.prevent="onSubmit">
    <b-form-group label="Идентификатор пользователя">
      <b-form-input v-model="$v.bonusData.userId.$model"
                    :state="$v.bonusData.userId.$dirty ? !$v.bonusData.userId.$error : null"
                    step="1"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.bonusData.userId.required">
        Идентификатор пользователя должен быть указан
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="!($v.bonusData.userId.integer && $v.bonusData.userId.minValue)">
        Идентификатор пользователя указан неправильно
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group :label="reduceBonus ? 'Число бонусов для списания' : 'Число бонусов для начисления'">
      <b-form-input v-model="$v.bonusData.addedBonusAmount.$model"
                    :state="$v.bonusData.addedBonusAmount.$dirty ? !$v.bonusData.addedBonusAmount.$error : null"
                    step="0.01"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.bonusData.addedBonusAmount.required">
        Количество бонусов не указано
      </b-form-invalid-feedback>
      <b-form-invalid-feedback
          v-if="!($v.bonusData.addedBonusAmount.decimal && $v.bonusData.addedBonusAmount.minValue)">
        Количество бонусов указано некорректно
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Токен подтверждения">
      <b-form-input v-model="$v.bonusData.userAuthToken.$model"
                    type="text"
                    :state="$v.bonusData.userAuthToken.$dirty ? !$v.bonusData.userAuthToken.$error : null"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.bonusData.userAuthToken.$required">
        Токен подтверждения должен быть указан при списании бонусов
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button v-if="showSubmit" class="mb-2" :disabled="$v.bonusData.$anyError" type="submit">Подтвердить</b-button>
    <b-alert v-model="submitSuccess" variant="success" dismissible>
      {{ reduceBonus ? 'Бонусы успешно списаны' : 'Бонусы начислены успешно' }}
    </b-alert>
    <b-alert v-model="submitFailure" variant="danger" dismissible>
      {{ reduceBonus ? 'Произошла ошибка при списании бонусов' : ' Произошла ошибка при начислении бонусов' }}
    </b-alert>
  </b-form>
</template>
<script>
import {required, decimal, integer, minValue, maxValue} from "vuelidate/lib/validators";
import users from '@/modules/users';

export default {
  name: 'user-bonus-form',
  validations() {
    if (this.reduceBonus) {
      return {
        bonusData: {
          userId: {
            required,
            integer,
            minValue: minValue(1)
          },
          addedBonusAmount: {
            required,
            decimal,
            minValue: minValue(0.005)
          },
          userAuthToken: {
            required
          }
        }
      };
    } else {
      return {
        bonusData: {
          userId: {
            required,
            integer,
            minValue: minValue(1)
          },
          addedBonusAmount: {
            required,
            decimal,
            minValue: minValue(0.005)
          },
          userAuthToken: {
            required
          }
        }
      };
    }
  },
  data() {
    return {
      bonusData: {
        userId: 0,
        addedBonusAmount: 0,
        userAuthToken: ""
      },
      submitSuccess: false,
      submitFailure: false
    };
  },
  props: {
    showSubmit: {
      type: Boolean,
      default: true
    },
    reduceBonus: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onSubmit(ev) {
      this.$v.bonusData.$touch();
      if (this.$v.bonusData.$anyError) {
        return;
      }
      this.submitSuccess = false;
      this.submitFailure = false;
      users.addUserBonus(Number.parseInt(this.bonusData.userId), this.bonusData.userAuthToken === "" ? null : this.bonusData.userAuthToken, (this.reduceBonus ? -1 : 1) * Number(this.bonusData.addedBonusAmount))
          .then(resp => {
            this.submitSuccess = true;
            this.$emit('bonus-updated');
          })
          .catch(err => {
            this.submitFailure = true;
          });
    }
  }
}
</script>

