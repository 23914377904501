import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login.vue';
import RootPage from '@/views/RootPage.vue';
import UserBonusAddition from '@/views/users/UserBonusAddition';
import User from '@/views/users/User';
import store from "@/store";
import UserToken from "@/views/users/UserToken";
Vue.use(Router);

const router = new Router({
    mode: 'history',
    base:  window.__POWERED_BY_QIANKUN__ ? '/service/bonus/' : '/',
    routes: [
        {path: '/', component: RootPage, meta: {title: 'Главная страница' }},
        {path: '/login', component: Login,  meta: {title: 'Вход'}},
        {path: '/user/:id', component: User, meta: { title: 'Редактирование пользователя'}},
        {path: '/userToken/:id', component: UserToken, meta: { title: 'Получение токена пользователя'}},
        {path: '/bonus/add', component: UserBonusAddition, props: {reduceBonus: false}, meta: { title: 'Начисление бонусов'}},
        {path: '/bonus/reduce', component: UserBonusAddition, props: {reduceBonus: true}, meta: { title: 'Списание бонусов'}}
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(error => {
                if (error.response?.status !== 401) {
                    console.error("Ошибка обновления токена", error);
                }
            });
    }

    const directingPath = to.path.endsWith('/') ? to.path.slice(0, -1) : to.path;

    if (!store.getters.isAuthorized && '/login' !== directingPath) {
        return next('/login');
    }
    if (store.getters.isAuthorized && '/login' === directingPath) {
        return next('/');
    }
    if (store.getters.isAuthorized && store.getters.isRolesEmpty && '/' !== directingPath) {
        return next('/');
    }
    if (!store.getters.isAdmin && ['/bonus/add', '/bonus/reduce'].includes(directingPath)) {
        return next('/');
    }
    if (!store.getters.isAdmin && directingPath.includes('/userToken')) {
        return next('/');
    }
    return next();
});

export default router;
