<template>
    <b-form>
        <b-form-group label="Число бонусов">
            <b-form-input v-model="$v.addedBonusAmount.$model"
                :state="$v.addedBonusAmount.$dirty ? !$v.addedBonusAmount.$error : null"
                step="0.01"
            >
            </b-form-input>
            <b-form-invalid-feedback v-if="!$v.addedBonusAmount.required">
                Количество бонусов не указано
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.addedBonusAmount.decimal">
                Количество бонусов указано некорректно
            </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Токен подтверждения">
            <b-form-input v-model="$v.userAuthToken.$model"
                type="text"
                :state="$v.userAuthToken.$dirty ? !$v.userAuthToken.$error : null"
            >
            </b-form-input>
          <b-form-invalid-feedback v-if="!$v.userAuthToken.required">
            Токен не указан
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button class="mr-2" variant="primary" v-if="showSubmit" :disabled="!$v.userAuthToken.required || $v.addedBonusAmount.$anyError" @click="onSubmit">Подтвердить</b-button>
        <b-button class="btn-close" v-if="showSubmit" @click="onClose()">Отмена</b-button>
    </b-form>
</template>
<script>
    import {required, decimal} from "vuelidate/lib/validators";
    import users from '@/modules/users';

    export default {
        mounted(){
        },
        validations(){
            return {
                addedBonusAmount: {
                    required,
                    decimal
                },
                userAuthToken: {
                    required
                }
            };
        },
        data(){
            return {
                userData: {},
                userAuthToken: '',
                addedBonusAmount: 0
            };
        },
        props: {
            userId: {
                type: Number,
            },
            reduceBonus: {
                type: Boolean,
                default: false
            },
            showSubmit: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            onSubmit(ev){
                this.$v.$touch();
                if (this.$v.anyError){
                    return;
                }
                users.addUserBonus(this.userId, this.userAuthToken === "" ? null : this.userAuthToken ,
                                   (this.reduceBonus ? -1 : 1) * Number(this.addedBonusAmount))
                    .then(resp => {
                        this.$emit('bonus-updated');
                    })
                    .catch(resp => {
                        this.$emit('bonus-not-updated');
                    });
            },
          onClose(){
              this.$emit('bonus-modal-close');
          }
        }
    }
</script>

