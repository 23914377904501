<template>
    <div>
        <b-navbar v toggleable="lg" variant="primary">
            <b-navbar-nav>
                <template v-if="!isAuthorized">
                    <b-nav-item v-bind:to="'/login'">Вход</b-nav-item>                    
                </template>
                <template v-else>
                    <b-nav-item v-bind:to="'/'">Главная</b-nav-item>
                    <b-nav-item v-if="isAdmin" v-bind:to="'/bonus/add'">Начислить бонусы</b-nav-item>
                    <b-nav-item v-if="isAdmin" v-bind:to="'/bonus/reduce'">Списать бонусы</b-nav-item>
                    <b-nav-item v-if="!this.$subapp" @click="logoutClicked">Выход</b-nav-item>
                </template>
            </b-navbar-nav>
        </b-navbar>
        <b-container class="pt-2">
            <router-view></router-view>
        </b-container>
        <AppInfo/>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AppInfo from "./AppInfo";

export default {
  name: "app",
    components: {AppInfo},
    mounted() {
    document.title = "Бонусы";
  },
  computed: {
    ...mapGetters([
        "isAuthorized",
        "isAdmin"
    ])
  },
  methods: {
    ...mapActions(["logout"]),
    logoutClicked() {
      this.logout()
          .finally(() => {
            this.$router.push("/login");
          });
    }
  }
};
</script>
<style scoped>
.nav-item.nav-item.nav-item a {
  color: white;
}
</style>
