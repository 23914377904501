<template>
  <div v-if="isRolesEmpty">
    <h1 class="my-4">У вас нет доступа к данному сервису</h1>
  </div>
  <div v-else>
    <user-list></user-list>
  </div>
</template>
<script>
import UserList from './users/UserList';
import {mapGetters} from "vuex";

export default {
  name: 'root-page',
  components: {
    'user-list': UserList
  },
  computed: {
    ...mapGetters([
        "isRolesEmpty"
    ])
  }
};
</script>
