<template>
  <b-form v-if="userData != null" @submit.stop.prevent="onSubmit">
    <b-form-group label="Имя">
      <b-form-input
          v-model="$v.userData.userName.$model"
          :state="$v.userData.userName.$dirty ? !$v.userData.userName.$error : null"
          :disabled="disabled || !isAdmin"
          aria-described-by="name-live-feedback"
      />
      <b-form-invalid-feedback ref="name-live-feedback">
        Имя должно содержать хотя бы один символ
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Число бонусов">
      <b-form-input
          type="number"
          v-model="userData.bonusAmount"
          :disabled="true"
      />
    </b-form-group>
    <b-button class="d-block mb-2" v-if="bonusHistory && bonusHistory.length > 0"
        @click="showHistory = !showHistory"
        size="sm">Отобразить историю</b-button>
    <b-collapse v-model="showHistory" v-if="bonusHistory && bonusHistory.length > 0">
      <b-list-group>
        <b-list-group-item v-for="(historyItem,index) in bonusHistory" :key="index" class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <h5 v-if="historyItem.bonusAmount > 0" class="mb-1">Начисление {{ historyItem.bonusAmount }} бонусов</h5>
            <h5 v-else class="mb-1">Списание {{ -historyItem.bonusAmount}} бонусов</h5>
            <small class="text-muted">{{formatDate(historyItem.bonusEventTime)}}</small>
          </div>
          <p class="mb-1" v-if="historyItem.eventSource">
            Источник: {{historyItem.eventSource}}
          </p>
        </b-list-group-item>
      </b-list-group>
      <b-button v-if="bonusHistoryTotalCount != null && bonusHistoryOffset <= bonusHistoryTotalCount"
        class="mt-2"
        @click="loadHistory">Загрузить</b-button>
    </b-collapse>
    <b-button class="d-block mt-2" v-if="isAdmin" type="submit" :disabled="disabled || $v.userData.$anyError">
      Сохранить
    </b-button>
    <b-button class="d-block mt-2" v-else v-bind:to="'/'" :disabled="disabled || $v.userData.$anyError">
      Назад
    </b-button>
    <b-alert class="mt-2" variant="info" :show="submitting">
      Сохранение...
    </b-alert>
    <b-alert class="mt-2" variant="danger" dismissible @dismissed="error=null" :show="errorMessage!=null">
      {{ errorMessage }}
    </b-alert>
  </b-form>
</template>
<script>
import moment from 'moment';
import {required} from "vuelidate/lib/validators";
import users from '@/modules/users';
import {mapGetters} from "vuex";

export default {
  mounted() {
    if (this.$route.params.id != null) {
      this.reloadUserData(this.$route.params.id)
          .then(() => {
            return this.reloadBonusHistory(this.$route.params.id)
          });
    }
  },
  computed: {
    ...mapGetters([
       "isAdmin"
    ]),
    errorMessage() {
      if (this.error == null || this.error.response == null) {
        return null;
      }
      if (this.error.response.status == 403) {
        return 'Ошибка: доступ запрещён';
      } else if (this.error.response.status == 401) {
        return 'Необходим повторный вход';
      } else {
        return 'Произошла ошибка при сохранении';
      }
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSubmit() {
      this.$v.userData.$touch();
      if (this.$v.userData.$anyError) {
        return;
      }
      this.error = null;
      this.submitting = true;
      users.updateSingle(this.userData)
          .then(resp => {
            this.$router.push('/');
          })
          .catch(err => {
            this.error = err;
          })
          .finally(() => {
            this.submitting = false;
          });
      return false;
    },
    reloadUserData(userId) {
      return users.getSingle(userId)
        .then(resp => {
          this.userData = resp.data;
          document.title = this.$route.meta.title + ' ' + resp.data.userName;
          return Promise.resolve(this.userData);
        });
    },
    loadHistory() {
      return users.getBonusHistory(this.$route.params.id, this.bonusHistoryOffset, this.bonusHistoryPerPage)
                  .then(resp => {
                    resp.data.results.forEach(item => {
                      this.bonusHistory.push(item);
                    });
                    this.bonusHistoryOffset = resp.data.offset + resp.data.limit;
                    this.bonusHistoryTotalCount = resp.data.totalCount;
                    return Promise.resolve(this.bonusHistory);
                  });
    },
    reloadBonusHistory(userId) {
      this.bonusHistoryOffset = 0;
      this.bonusHistoryTotalCount = null;
      return users.getBonusHistory(userId, this.bonusHistoryOffset, this.bonusHistoryPerPage)
          .then(resp => {
            this.bonusHistory = resp.data.results;
            this.bonusHistoryOffset = resp.data.offset + resp.data.limit;
            this.bonusHistoryTotalCount = resp.data.totalCount;
            return Promise.resolve(this.bonusHistory);
          });
    },
    formatDate(dateValue) {
      return moment(dateValue).format('DD MMMM YYYY, HH:mm:ss')
    }
  },
  validations() {
    return {
      userData: {
        userName: {
          required
        }
      }
    };
  },
  data() {
    return {
      submitting: false,
      error: null,
      userData: null,
      bonusHistory: null,
      bonusHistoryOffset: 0,
      bonusHistoryPerPage: 20,
      bonusHistoryTotalCount: null,
      showHistory: false,
    };
  }
}
</script>
