import {AXIOS} from './backend-config';
export default {
  getUserList(offset, limit){
    return AXIOS.get('/user/', {
      params: {
        offset,
        limit
      }
    });
  },
  getSingle(userId) {
    return AXIOS.get(`/user/${userId}`, {});
  },
  getBonusHistory(userId, offset, limit) {
    return AXIOS.get(`/user/${userId}/bonus_history`, {
      params: {
        offset,
        limit
      }
    });
  },
  updateSingle(user){
    return AXIOS.put(`/user/${user.id}`, user);
  },
  addUserBonus(userId, userAuthToken, bonusAmount){
    return AXIOS.put(`/user/${userId}/bonus/add`, {
      amount: bonusAmount,
      authToken: userAuthToken
    });
  },
  getUserToken(userId){
    return AXIOS.get(`/user/token/${userId}`, {});
  },
}
