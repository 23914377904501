<template>
  <div>
    <b-form v-if="$route.params.id != null" @submit.stop.prevent="onSubmit">
      <b-form-group label="Идентификатор пользователя">
        <b-form-input
            v-model="$route.params.id"
            :disabled="true"
        />
      </b-form-group>
      <b-form-group label="Токен">
        <b-form-input
            v-model="userToken"
            :disabled="true"
        />
      </b-form-group>
      <b-button type="submit" :disabled="submitting">
        Получить токен
      </b-button>
      <b-button class="ml-3" v-bind:to="'/'">
        Назад
      </b-button>
      <b-alert class="mt-2" variant="info" :show="submitting">
        Получение...
      </b-alert>
      <b-alert class="mt-2" variant="danger" dismissible @dismissed="error=null" :show="error!=null">
        {{ error }}
      </b-alert>
    </b-form>
    <div v-else>
      <h6 class="text-center">Не удалось отобразить страницу</h6>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import users from "@/modules/users";
import {required} from "vuelidate/lib/validators";

export default {
  name: "UserToken",
  computed: {
    ...mapGetters([
      "isAdmin"
    ]),
  },
  methods: {
    onSubmit() {
      this.error = null;
      this.submitting = true;
      users.getUserToken(this.$route.params.id)
          .then(resp => {
            this.userToken = resp.data.token
          })
          .catch(err => {
            if (err.response.status === 403) {
              this.error = 'Ошибка: доступ запрещён';
            } else if (err.response.status === 401) {
              this.error = 'Необходим повторный вход';
            } else {
              this.error = 'Произошла ошибка при получении токена';
            }
          })
          .finally(() => {
            this.submitting = false;
          });
      return false;
    },
  },
  data() {
    return {
      submitting: false,
      error: null,
      userToken: null
    };
  }
}
</script>

<style scoped>

</style>